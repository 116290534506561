import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import '@mod-system/js/wh/integration';
//import './debug';
import './aside';
import './footer';
import './header';
import './shared/rtd/rtd';
import './vademecumsite.scss';
import './pages/search';
import './pages/vademecum';
import './widgets/button';
import "./shared/lightbox";

dompack.onDomReady(() =>
{
  openLinksInNewWindow({ extensions : ["pdf"] });
});
