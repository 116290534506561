import "./header.scss";
import "./mobilenav.scss";
import * as dompack from 'dompack';
import * as swipe from 'dompack/browserfix/swipelistener';

class mobileNav
{
  constructor(node)
  {
    this.node = node;

    let togglebtn = dompack.qS("header .showmobilenavtoggle");

    togglebtn.addEventListener("click", ev => this.show());
    togglebtn.addEventListener("keyup", ev => {
      if( ev.keyCode == 13 && !document.documentElement.classList.contains("showmobilenav") )
        this.show();
    });

    swipe.enable(this.node);
    this.node.addEventListener("dompack:swipe", ev => {
      if( ev.detail.direction == "e" )
        this.close();
    });

    document.addEventListener("keyup", ev => {
      if( ev.keyCode == 27 && document.documentElement.classList.contains("showmobilenav") )
        this.close();
    });

    this.node.addEventListener("click", ev => {
      if( ev.target.closest("*[data-action='closemobilenav']") )
        this.close(ev);
    });
  }

  show()
  {
    document.documentElement.classList.add("showmobilenav");
  }

  close(ev)
  {
    document.documentElement.classList.remove("showmobilenav");
  }
}

dompack.register(".mobilenav", node => new mobileNav(node));
