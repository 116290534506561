import "./xlightbox.css";
import "designfiles/frameworks.mootools";

import "./mootools-more.js";
import "./xtlightbox.js";
import "./adaptor.js";
import "./renderer.js";
import "./adaptor/image.js";
import "./adaptor/youtube.js";
import "./adaptor/vimeo.js";
import "./renderer/lightbox.js";

(function($) { //mootools/scope wrapper
"use strict";

function lightbox_init()
{
  var lightboxitems = $$("a[rel='lightbox']");
  if(lightboxitems.length)
  {
    new XtLightbox( lightboxitems
                  , { loop: true
                    , adaptorOptions: { Image: { lightboxCompat: false, extensions: [] } }
                    , rendererOptions:{ closeText: 'Sluiten' }
                    }
                  );
  }
}

window.addEvent("domready", lightbox_init);

})(document.id); //end mootools/scope wrapper
